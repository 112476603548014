function sendLog(level, message) {
    const meta = document.querySelector('meta[http-equiv="Authorization"]');
    fetch(window.ajaxPath + '/log', {
        method: 'POST',
        body: JSON.stringify({
            level: level,
            message: message,
            site: window.siteCode,
            language: window.language,
            playerToken: window.playerToken
        }),
        headers: {
            'Authorization': 'Bearer ' + meta?.content
        },
    })
        .catch((error) => {
        console.log(error);
    });
}
export default sendLog;
