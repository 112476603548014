import sendLog from '../../logger';
document.body.addEventListener('click', (event) => {
    const target = event.target;
    if (target.id === 'start-chat') {
        const chatInlay = document.getElementById('chatInlay');
        if (chatInlay) {
            setTimeout(() => {
                if (chatInlay.getAttribute('data-oit-mode') === 'hidden') {
                    sendLog('error', 'Chat inlay could not be opened');
                }
            }, 4000);
        }
        else {
            sendLog('error', 'Chat inlay could not be loaded');
        }
    }
});
